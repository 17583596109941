import React from 'react';
import { Parallax } from 'react-parallax';
import {Link} from 'react-scroll'

import './App.scss';

import logo from './images/logo.png'
import hamburger from './images/menu.svg'
import close from './images/cancel.svg'
import parallaxImage from './images/one2SmlP.jpg'

class LPNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {active: false}; 
  }

  navActive(r) {
    if(r) {
      this.setState({
        active: false
      })
      document.getElementById("hamburger").setAttribute('src', hamburger)
      document.getElementById("links").removeAttribute('style')
    }else{
      this.setState({
        active: true
      })
      document.getElementById("hamburger").setAttribute('src', close)
      document.getElementById("links").setAttribute('style', "display: block")
    }
  }

  navIfActive(r){
    if(window.innerWidth <= 792) {
      this.navActive(this.state.active)
    }
  }

  render() {
    return (
      <nav>
        <div className="logo">
          <div className="logoNav">
            <img src={logo} alt=""/>
            <header>Marta Lipin</header>
            <p>Lekarz Psychiatra</p>
          </div>
        </div>
        <img id="hamburger" onClick={() => this.navActive(this.state.active)} src={hamburger} alt="HAMBURGER" />
        <div id="links" className="links">
          <ul>
            <li><Link activeClass="active" to="home" spy={true} smooth={true} offset={-70} onClick={() => this.navIfActive()}>Strona główna</Link></li>
            <li><Link to="omnie" spy={true} smooth={true} offset={-70} onClick={() => this.navIfActive()}>O mnie</Link></li>
            <li><Link to="oferta" spy={true} smooth={true} offset={-70} onClick={() => this.navIfActive()}>Oferta</Link></li>
            <li><Link to="kontakt" spy={true} smooth={true} offset={-70} onClick={() => this.navIfActive()}>Kontakt</Link></li>
          </ul>
        </div>
      </nav>
    )
  }
}

function LPParallax() {
  return (
    <Parallax bgImage={parallaxImage} strength={0}>
      <div id="home">
        <div>
          <h1>Marta Lipin</h1>
          <p>Twój umysł to Twoja siła</p>
        </div>
      </div>
    </Parallax>
  )
}

function LPAboutMe(props) {
  return (
    <div className="parallax-box" id="omnie">
      <header>O mnie</header>
      <p>
        Lekarz specjalista psychiatra z wieloletnim doświadczeniem zawodowym, zarówno w leczeniu ambulatoryjnym (poradnia, gabinet) jak i szpitalnym (oddział psychiatryczny, oddział psychiatryczny dla chorych z chorobami somatycznymi). Wieloletni biegły sądowy w dziedzinie psychiatrii.
      </p>
      <br />
      <p>
        Od 2001 roku prowadzę prywatna praktykę lekarską.
      </p>
      <br />
      <p>
        Oferuję terapię (porady psychiatryczne – farmakoterapię, elementy terapii poznawczo – behawioralnej) w:
        <br />
        <b>
        depresji,
        chorobie afektywnej dwubiegunowej,
        psychozach,
        nerwicach,
        zaburzeniach związanych ze stresem,
        napadach paniki,
        zaburzeniach lękowych,
        zaburzeniach snu
        </b>
      </p>
      <br/>
      <p>
        Prowadzę także psychoedukację opiekunów pacjentów z otępieniem oraz leczenie otępień w tym choroby Alzheimera.
      </p>
      <br />
      <p>
        Przy współpracy z terapeutą leczenia uzależnień, Andrzejem Proszowskim zajmuję się leczeniem uzależnień (alkohol, leki, substancje psychoaktywne, hazard).
      </p>
    </div>
  )
}

function LPServices(props) {
  return (
    <div id="oferta" className="parallax-box">
      <header>Oferta wizyt</header>
      <div className="gridService">
        <div className="service-1">
          <div className="card">
            <div>
              <p>wizyty w gabinecie usytuowanym</p>
            </div>
          </div>
        </div>
        <div className="service-2">
          <div className="card">
            <div>
              <p>nagłe interwencje psychiatryczne</p>
            </div>
          </div>
        </div>
        <div className="service-3">
          <div className="card">
            <div>
              <p>wizyty domowe</p>
            </div>
          </div>
        </div>
        <div className="service-4">
          <div className="card">
            <div>
              <p>teleporady</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class LPContact extends React.Component {
  render() {
    return (
      <div id="kontakt" className="parallax-box">
        <header>Kontakt</header>
        <p>Wszystkie wizyty odbywają się po wcześniejszym uzgodnieniu telefonicznym.</p>
        <ul className="list-group">
          <li>
            <header>ADRES:</header>
              <p>ul. Fabianiego 7</p>
              <p>97-500 Radomsko</p>
          </li>
          <li>
            <header>TELEFON:</header>
            <p><a href="tel:+48-604-207-563">tel. 604 207 563</a></p>
          </li>
          <li>
            <header>E-MAIL:</header>
            <p><a href="mailto:m.lipin-3@o2.pl">m.lipin-3@o2.pl</a></p>
          </li>
        </ul>
        <div className="map-grid">
          <div className="map">
            <iframe title='Google Embed' src="https://maps.google.com/maps?hl=pl&amp;q=ul. Fabianiego 7, 97-500 Radomsko&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
          </div>
        </div>
    </div>
    )
  }
}

function LPFooter(props) {
  return (
    <div className="parallax-box">
      <footer className="footerGrid">
        <div className="footer-1">
          <div className="logoFooter">
            <img src={logo} alt=""/>
            <header>Marta Lipin</header>
            <p>Lekarz Psychiatra</p>
          </div>
        </div>
        <div className="footer-2">
          <header>KONTAKT</header>
          <p>Marta Lipin</p>
          <p>ul. Fabianiego 7</p>
          <p>97-500 Radomsko</p>

          <p>m.lipin-3@o2.pl</p>
          <p>tel. kom. 604 207 563</p>
        </div>
        <div className="footer-3">
          <header>STRONY</header>
            <p><Link to="home" smooth={true}>Strona główna</Link></p>
            <p><Link to="omnie" smooth={true} offset={-70}>O mnie</Link></p>
            <p><Link to="oferta" smooth={true} offset={-70}>Oferta</Link></p>
            <p><Link to="kontakt" smooth={true} offset={-70}>Kontakt</Link></p>
        </div>
      </footer>
  </div>
  )
}

class App extends React.Component {
  render() {
    return (
      <>
        <LPNav />
        <LPParallax />
        <LPAboutMe />
        <LPServices />
        <LPContact />
        <LPFooter />
      </>
    );
  }
}

export default App;
